import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const SmallLogo = () => {
   return (
     <div>
        <StaticImage
          src="../images/OnestepS_Logo-white14.png"
          alt="Small Logo"
          width={40}
          height={40}
        />
    </div>
   )
 }