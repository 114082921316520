import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const RootLogo = () => {
   return (
     <div className="logo-outline">
        <StaticImage
          src="../images/home-logo-whole.png"
          alt="Root Logo"
        />
    </div>
   )
 }