import React from "react"
import { Link } from "gatsby"
import { Logo } from "../components/header-logo"



const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <div className="global-header">
        <div className="global-header-image">
          <div className="root-header-logo">
            <Logo isRootPath={isRootPath}/>
          </div>
        </div>
      </div>
    )
  } else {
    header = (
      <div className="post-header-wrapper">
      <div className="post-header">
        <Link className="header-link-home" to="/">
          <div className="small-header-logo">
            <Logo isRootPath={isRootPath}/>
          </div>
          <span className="header-title">{title}</span>
        </Link>
      </div>
      </div>
    )
  }

  return (
    <div>
      <header>{header}</header>
      <div className="global-wrapper" data-is-root-path={isRootPath}>

        <main>{children}</main>
        <footer
          className="copy-light"
        >
          <div className="item-place">
            © {new Date().getFullYear()} One stepS
            {` `}
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Layout

