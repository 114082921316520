import React from "react"
import { RootLogo } from "./RootLogo"
import { SmallLogo } from "./SmallLogo"

export const Logo = ({isRootPath = false}) => {
   return (
     <div>
       {
          isRootPath ? <RootLogo /> : <SmallLogo />
       }
    </div>
   )
 }